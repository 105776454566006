@import "./fade.animation.css";
@import "./probe-source-target-header.css";
@import "./hamburger.css";

body {
  min-height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@tailwind base;
@tailwind components;

.code {
  font-family: "Source Code Pro", monospace;
  padding-top: 1px;
  @apply text-sm border rounded-md border-gray-400 px-1;
}

.MuiButton-root.MuiButton-containedPrimary .MuiButton-label {
  color: #fff;
}

.no-border .MuiInputBase-root {
  border:none;
}

/* .table > thead > tr > th{
  background-color: #EEEE00;
  } */

@tailwind utilities;

